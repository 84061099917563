.page__information {
  padding-top: 34px;
}

.information {
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 70px;
    font-weight: 600;
    letter-spacing: -2.3333332538604736px;
    color: white;
    position: relative;
    padding-left: 88px;
    @media (max-width: 400.98px) {
      font-size: 60px;
    }
  }
  &__title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 64px;
    height: 64px;
    background: url("./assets/eyes.png") no-repeat 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__description {
    max-width: 890px;
    font-weight: 400;
    font-size: 25px;
    line-height: 33px;
    color: #fff;
  }

  &__form-container {
    display: flex;
    margin-top: 27px;
    gap: 10px;
    @media (max-width: 797.98px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__input-wrap {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__input-text {
    font-size: 20px;
    font-weight: 400;
    color: white;
  }

  &__input {
    height: 60px;
    border: 0 solid;
    background: hsla(0, 0%, 100%, 0.24);
    mix-blend-mode: normal;
    border-radius: 12px;
    padding-left: 25px;
    margin-right: 10px;
    color: #fff;
    font-size: 25px;
    line-height: 33px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    width: 100%;
    outline: none;
    @media (max-width: 797.98px) {
      max-width: 100%;
    }
  }
  &__input::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }

  &__btn {
    position: relative;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    height: 60px;
    min-width: 100px;
    cursor: pointer;
    display: inline-block;
    border: 3px solid #fff;
    transition: all 0.2s;
    @media (max-width: 797.98px) {
      width: 100%;
    }
  }
  &__btn img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 28px;
    height: 26px;
  }
  &__btn:hover {
    transform: scale(1.05);
    transition: all 0.2s;
  }
}

.notification-access {
  position: fixed;
  top: 81px;
  left: 50%;
  transform: translate(-50%);
  padding: 8px 51px 9px 22px;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 25px;
  letter-spacing: -0.1px;
  display: inline-block;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -20px);
  transition: all 0.8s;
  z-index: 99999;
  @media (max-width: 767.98px) {
    top: 45px;
    padding: 8px 20px 9px 20px;
  }
}
.notification-access.show {
  opacity: 1;
  transform: translate(-50%, 0);
  visibility: visible;
  transition: all 0.8s;
}
.notification-access.red {
  background: #f55;
}
.notification-access.green {
  background: #1fcb3e;
}
