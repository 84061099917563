.page__cards {
    margin-bottom: 80px;
}

.cards {
    &__content {
        background: white;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
        padding: 35px 50px;
        border-radius: 12px;    
    }
    &__title {
        position: relative;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -2.33333px;
        font-weight: 600;
        color: rgba(49, 49, 49, 1);
        display: flex;
        align-items: center;
        gap: 22px;
        margin-bottom: 30px;
        @media (max-width: 400.98px) {
          font-size: 60px;
        }
        span {
            color: rgba(157, 157, 157, 1);
        }
      }

      &__items {
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        row-gap: 15px;
      }

      &__item-wrap {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 122px;
        width: 221px;
        background-color: rgba(236, 242, 247, 1);
        border-radius: 10px;
        padding: 22px;
      }

      &__icon {
        width: 40px;
        height: 30px;
      }
      &__btn {
        background: rgba(236, 242, 247, 1);
        font-size: 20px;
        font-weight: 400;
        line-height: 23.44px;
        display: flex;
        justify-content: center;
        padding: 5px 0;
        border-radius: 10px;
        color: rgba(157, 175, 196, 1);
        cursor: pointer;
        transition: all 0.2s;
      }
      &__btn:hover {
        background: rgb(221, 226, 230);
      }

}