.page__tariff {
    padding-top: 71px;
    padding-bottom: 50px;
}

.tariff {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    position: relative;
    font-size: 70px;
    line-height: 82px;
    letter-spacing: -2.33333px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 10px;
    padding-left: 88px;
    @media (max-width: 400.98px) {
        font-size: 60px;
      }
  }

  &__title::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 64px;
    height: 64px;
    background: url('./assets/arm.png');
    background-size: contain;
    left: 0;
    background-repeat: no-repeat;
  }

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 23px;
    padding-left: 88px;
    @media(max-width: 797.98px) {
        flex-wrap: wrap;
        padding-left: 0;
        justify-content: center;
        margin-top: 40px;
        align-items: center;
    }
    @media(max-width: 727.98px) {
        flex-direction: column;
    }
  }

  &__item {
    box-shadow: 0 2px 60px rgba(182, 0, 74, .4);
    border-radius: 180px;
    width: 290px;
    height: 290px;
    margin-left: -68px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media(max-width: 797.98px) {
        width: 290px;
        height: 290px;
        margin-left: 0;
        margin-right: 50px;
        margin-bottom: 30px;
    }
    @media(max-width: 727.98px) {
        margin-right: 0;
    }
  }
  &__item.one {
    width: 330px;
    height: 330px;
    background: linear-gradient(155.7deg, #5b4de1 .8%, #e231ff 83.74%);
    @media(max-width: 797.98px) {
        width: 290px;
        height: 290px;
    }
  }
  &__item.two {
    background: linear-gradient(327.76deg, #ffd73d 15.97%, #ff6e29 97.53%);
  }
  &__item.three {
    background: linear-gradient(320.81deg, #b4ec51 11.52%, #58b233 95.54%);
  }

  &__period {
    color: #fff;
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 10px;
    font-weight: 700;
  }

  &__profile {
    color: #fff;
    font-size: 25px;
    line-height: 29px;
    margin-bottom: 14px;
  }

  &__price {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    margin-bottom: 19px;
  }

  &__button {
      padding: 5px 24px 4px;
      display: inline-block;
      text-align: center;
      letter-spacing: -.461538px;
      font-size: 20px;
    line-height: 33px;
    border: 3px solid #fff;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0 2px 6px rgba(0, 0, 0, .15));
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, .15));
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: all .2s;
    background-color: transparent;
    color: #fff;
}
&__button:hover {
    transform: scale(1.05);
}
&__button.fill {
    background-color: #fff;
    color: #bf137b;
  }
}
