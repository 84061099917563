.info-rule {
  &__content {
    background: #ffffff;
    padding: 32px 50px;
    border-radius: 12px;
    @media (max-width: 767.98px) {
      padding: 20px 20px;
    }
    @media (max-width: 490.98px) {
      margin-bottom: 50px;
    }
  }

  &__content-wrap-text:not(:last-child) {
    margin-bottom: 50px;
  }
  &__content-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 46.88px;
    color: #313131;
    @media (max-width: 767.98px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  &__content-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    margin-top: 23px;
    color: #313131;
    @media (max-width: 767.98px) {
      font-size: 16px;
    }
  }
  &__content-date {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: #313131;
    margin-top: 35px;
    @media (max-width: 767.98px) {
      font-size: 16px;
    }
  }
  &__content-list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
  }
  &__content-list-title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  &__content-item {
    list-style-type: disc;
    margin-left: 25px;
  }
}
