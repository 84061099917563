@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&subset=cyrillic");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
  margin: 0;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0 !important;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  font-family: "Roboto", sans-serif;
}

/* 
* {
  -ms-overflow-style: none;
  scrollbar-width: none; 
}
*::-webkit-scrollbar {
  display: none; 
} */

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(44.13deg, #F8A64D -3.9%, #EF4430 32.8%, #DB1466 68.06%, #A41290 100%);
}

.wrapper.white {
  background: rgba(241, 235, 238, 1);
}

.page {
  flex: 1 1 auto;
}

@media (max-width: 1199.98px) {
  .page {
    margin: 0;
  }
}

.row {
  margin: 0 auto 0 auto;
}

[class*=__container] {
  max-width: 938px;
  margin: 0 auto;
}

[class*=page__container] {
  background-color: white;
}

[class*=__contain-dashboard] {
  max-width: 1220px;
  margin: 0 auto;
  background-color: white;
}

[class*=__container-dashboard] {
  max-width: 1220px;
  margin: 0 auto;
}

@media (min-width: 2430px) {
  [class*=__container] {
    max-width: 2720px;
  }
}

@media (max-width: 1199.98px) {
  [class*=__container] {
    max-width: 970px;
  }

  [class*=__contain-dashboard] {
    max-width: none;
    margin: 0;
  }

  [class*=__container-dashboard] {
    max-width: none !important;
    margin: 0px 24px !important;
  }
}

@media (max-width: 990.98px) {
  [class*=__container] {
    max-width: none;
    margin: 0px 24px;
  }
}

.__className_e66fe9 {
  min-width: 100%;
}

/*# sourceMappingURL=globals.css.map */