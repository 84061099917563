.page__accounts {
  overflow: hidden;
  width: 100%;
  height: 150px;
  position: relative;
  @media (max-width: 797.98px) {
    height: 50px;
  }
}

.accounts {
  &__content {
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: center;
  }
  &__items {
    display: flex;
    gap: 16px;
    @media (max-width: 462.98px) {
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
  }
  &__item {
    width: 115px;
    height: 115px;
    border: 3px solid rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      @media (max-width: 797.98px) {
        width: 40px;
        height: 40px;
      }
    }
    @media (max-width: 797.98px) {
      min-width: 50px;
      width: 50%;
      height: 50px;
    }
  }
}
