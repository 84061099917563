.header {
  padding: 41px 0;
  @media (max-width: 797.98px) {
    padding: 40px 0 30px 0;
  }
}

.header.gradient {
  background: linear-gradient(
    45deg,
    #f8a64d,
    #ef4430 33%,
    #db1466 68%,
    #a41290
  );
  height: 214px;
}

.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__left {
  display: flex;
  gap: 43px;
  align-items: center;
}

.header-left__logotype {
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -2.3333332538604736px;
  display: flex;
  align-items: center;
  color: white;
  gap: 11px;
  @media (max-width: 767.98px) {
    gap: 6px;
  }
  img {
    @media (max-width: 767.98px) {
      width: 35px;
      height: 35px;
    }
  }
  @media (max-width: 767.98px) {
    font-size: 30px;
  }
}

.language-drop {
  position: relative;
  &__icon {
    background-color: white;
    border: 1px solid rgba(159, 175, 191, 1);
    width: 54px;
    height: 41px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-size: 25px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.5769230723381042px;
    color: rgba(191, 19, 123, 1);
    position: relative;
    cursor: pointer;
  }
  &__icon::after {
    position: absolute;
    content: "";
    background: url("./assets/Line.svg");
    width: 25px;
    height: 55px;
    background-repeat: no-repeat;
    left: -31px;
  }

  &__list {
    background: linear-gradient(
      180deg,
      rgba(248, 166, 77, 0.96) 0%,
      rgba(239, 68, 48, 0.96) 35%,
      rgba(219, 20, 102, 0.96) 69%
    );
    border: 1px solid rgba(49, 49, 49, 0.4);
    position: absolute;
    top: 60px;
    border-radius: 25px;
    width: 235px;
    left: 50%;
    transform: translate(-50%);
  }
  &__item:not(:last-child) {
    border-bottom: 1px solid rgba(49, 49, 49, 0.4);
    cursor: pointer;
  }
  &__button {
    background: transparent;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.6923076510429382px;
    color: white;
    width: 100%;
    padding: 15px 0;
  }
  &__button.active {
  }
}

.profile-drop {
  position: relative;
  &__icon {
    width: 80px;
    height: 80px;
    background: transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767.98px) {
        width: 60px;
        height: 60px;
    }
  }
  &__icon.active {
    background: rgba(49, 49, 49, 0.1922);
  }
  &__list {
    background: linear-gradient(
      180deg,
      rgba(239, 68, 48, 0.96) 35%,
      rgba(219, 20, 102, 0.96) 69%
    );
    border: 1px solid rgba(49, 49, 49, 0.4);
    position: absolute;
    top: 80px;
    border-radius: 25px;
    width: 235px;
    left: 50%;
    transform: translate(-50%);
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767.98px) {
      top: 60px;
    }
  }
  &__item:not(:last-child) {
    border-bottom: 1px solid rgba(49, 49, 49, 0.4);
    cursor: pointer;
  }
  &__item {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 0;
    cursor: pointer;
  }
  &__button {
    background: transparent;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.6923076510429382px;
    color: white;
    padding: 5px 10px;
    width: 213px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.2s;
  }
  &__button:hover {
    background: rgba(49, 49, 49, 0.1922);
  }
  &__button.active {
  }
  &__wrap-tariff {
    padding: 15px 11px 0 11px;
  }
  &__tariff {
    background: linear-gradient(
      117.32deg,
      rgba(91, 77, 225, 0.9) 52.97%,
      rgba(226, 49, 255, 0.9) 97.87%
    );
    padding: 10px 11px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 23px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.6923076510429382px;
    color: white;
  }
}

.header-right {
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 767.98px) {
    gap: 10px;
  }
  img {
    transition: all 0.2s;
    cursor: pointer;
    width: 60px;
    height: 60px;
    @media (max-width: 767.98px) {
      width: 40px;
      height: 40px;
    }
  }
  img:hover {
    transform: scale(1.05);
    transition: all 0.2s;
  }
  &__button-deposit {
    background-color: #fff;
    color: #bf137b;
    padding: 5px 24px 4px;
    display: inline-block;
    text-align: center;
    letter-spacing: -0.461538px;
    font-size: 20px;
    border: 3px solid #fff;
    box-sizing: border-box;
    -webkit-filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.15));
    filter: drop-shadow(0 2px 6px rgba(0, 0, 0, 0.15));
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    height: 46px;
    @media (max-width: 767.98px) {
      font-size: 16px;
      padding: 5px 16px 4px;
    }
  }
  &__button-deposit:hover {
    transform: scale(1.05);
    transition: all 0.2s;
  }
}
