.navbar-info {
  margin-bottom: 37px;
  &__list {
    display: flex;
    gap: 12px;
    @media(max-width: 864.98px) {
        flex-direction: column;
        align-items: center;
    }
  }
  &__item {
    max-width: 427px;
    width: 100%;
    height: 60px;
    background: white;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
  }
  &__link {
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    width: 100%;
    height: 100%;
    color: #BF137B;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item:hover {
    background: rgba(228, 228, 228, 0.936);
    transition: all 0.2s;
  }
}
