.page__profiles {
}

.profiles {
  margin-top: -80px;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 35px 50px;
  margin-bottom: 16px;
  @media (max-width: 500px) {
    padding: 15px 20px;
  }
  &__title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 800;
    letter-spacing: -1.33333px;
    color: #313131;
    @media (max-width: 500px) {
      font-size: 28px;
    }
  }

  &__wrap {
    width: 100%;
    height: 110px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 29px 0 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @media (max-width: 500px) {
      height: 85px;
      margin-top: 10px;
    }
  }

  &__profile {
    width: 100px;
    height: 100px;
    margin-right: 16px;
    border: 0 solid;
    background-color: #ccc;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 500px) {
      width: 80px;
      height: 80px;
    }
  }

  &__profile.active {
    background: linear-gradient(
      45deg,
      #f8a64d,
      #ef4430 33%,
      #db1466 68%,
      #a41290
    );
  }
  &__profile__pic {
    width: 92px;
    height: 92px;
    border-radius: 100%;
    border: 4px solid #fff;
    transition: all 0.2s;
    @media (max-width: 500px) {
      width: 72px;
      height: 72px;
    }
  }

  &__profile__pic:hover {
    transition: all .2s;
    transform: scale(.95);
  }

  &__profile.active &__profile__pic {
    border: 3px solid #fff;
  }

  &__add-btn {
    border: 0 solid;
    width: 100px;
    height: 100px;
    cursor: pointer;
    transition: all .2s;
    background-color: transparent;
    @media (max-width: 500px) {
      width: 80px;
      height: 80px;
    }
  }

  &__add-btn_ic {
    width: 100px;
    height: 100px;
    transition: all .2s;
    @media (max-width: 500px) {
      width: 80px;
      height: 80px;
    }
  }
  &__add-btn_ic:hover {
    transition: all .2s;
    transform: scale(.95);
  }
}
