.page__telegram {
  margin-bottom: 16px;
}

.profile-telegram {
  &__content {
    padding: 35px 50px;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    background: linear-gradient(180deg, #38afe2, #1e94d3);
    cursor: pointer;
    font-size: 40px;
    line-height: 47px;
    font-weight: 800;
    letter-spacing: -1.33333px;
    color: #fff;
    width: 100%;
    display: block;
    @media (max-width: 500px) {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 800;
    letter-spacing: -1.33333px;
    color: #fff;
    @media (max-width: 500px) {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
    }
  }
}
