.footer {
  padding: 50px 0;
  @media(max-width: 490.98px) {
    padding: 0 0 50px 0;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @media(max-width: 555.98px) {
        flex-direction: column;
        gap: 20px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__link {
    color: white;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;
    transition: all 0.2s;
  }
  &__link:hover {
    color: rgba(255, 255, 255, 0.793);
  }

  &__telegram {
    background: #31313131;
    padding: 26px 12px;
    display: flex;
    gap: 7px;
    font-size: 30px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.6923076510429382px;
    color: white;
    border-radius: 25px;
    transition: all 0.2s;
    @media(max-width: 490.98px) {
        justify-content: center;
    }
  }
  &__telegram:hover {
    background: #31313147;
  }
}


.footer.gradient {
  background: linear-gradient(44.13deg, #F8A64D -3.9%, #EF4430 32.8%, #DB1466 68.06%, #A41290 100%);
  @media(max-width: 490.98px) {
    padding: 50px 0 50px 0;
  }
}