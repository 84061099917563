.history {
  margin-bottom: 16px;
  padding: 35px 50px;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  background: #fff;
  @media (max-width: 500px) {
    padding: 15px 20px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header-title {
    font-size: 40px;
    line-height: 47px;
    font-weight: 800;
    letter-spacing: -1.33333px;
    color: #313131;
    @media (max-width: 500px) {
      font-size: 28px;
    }
  }
  &__header-btns {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 650px) {
      display: none;
    }
  }
  &__header-btns__btn {
    width: 33px;
    height: 30px;
    border: 0 solid;
    padding: 0;
    cursor: pointer;
    transition: all 0.2s;
    background-color: transparent;
  }
  &__header-btns__btn:active {
    transform: scale(0.8);
  }
  &__header-btns__btn-ic {
    width: 33px;
    height: 30px;
  }
  &__header-btns__btn-div {
    width: 1px;
    height: 30px;
    margin: 0 20px;
    background-color: #e9e9e9;
  }
  &__istories {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    @media (max-width: 650px) {
      gap: 25px;
    }
    &__item-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    &__item {
      width: calc(50% - 8px);
      margin-top: 30px;
      @media (max-width: 650px) {
        width: 100%;
        margin: 0;
      }
    }
    &__item-header_avatar {
      margin-right: 14px;
      width: 60px;
      height: 60px;
      border-radius: 100%;
    }
    &__item-header__name {
      font-size: 20px;
      line-height: 23px;
      font-weight: 800;
      color: #4a90e2;
      margin-bottom: 4px;
    }
    &__item-header__date {
      font-size: 20px;
      line-height: 23px;
      font-weight: 400;
      letter-spacing: -0.666667px;
      color: rgba(49, 49, 49, 0.6);
    }
    &__item_content-wrap {
      width: 100%;
      padding: 8px;
      margin-right: 16px;
      border: 0 solid;
      background-color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-sizing: border-box;
    }
    &__item_content-wrap.active {
      background: linear-gradient(
        45deg,
        #f8a64d,
        #ef4430 33%,
        #db1466 68%,
        #a41290
      );
    }
    &__item_content {
      width: 100%;
      height: auto;
    }
    &__item-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__item-text-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: -0.6666666269302368px;
        text-align: center;
        max-width: 317px;
        width: 100%;
        color: rgba(49, 49, 49, 0.6);
        span {
          color: rgb(0, 133, 173, 0.6);
        }
      }
    }

    &__item-text-btn {
      font-size: 20px;
      font-weight: 400;
      line-height: 33px;
      letter-spacing: -0.4615384340286255px;
      text-align: center;
      background: linear-gradient(90deg, #367ef1 -4.47%, #1498e9 95.53%);
      padding: 8px 65px;
      color: white;
      border-radius: 15px;
      margin-top: 30px;
      transition: all 0.2s;
    }
    &__item-text-btn:hover {
      transform: scale(1.05);
    }
  }
}
